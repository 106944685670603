<script>
    import { onMount, onDestroy } from 'svelte'
    
    let canvas;
    export let delay;

    onMount(() => {
        const context = canvas.getContext('2d')
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight

        let particlesArray;
        let reqID;

        // let mouse = {
        //     x: canvas.width / 2,
        //     y: canvas.height / 2,
        //     radius: (canvas.height / 80) * (canvas.width / 80)
        // }

        class Particle {
            constructor(x, y, directionX, directionY, size, color) {
                this.x = x;
                this.y = y;
                this.directionX = directionX;
                this.directionY = directionY;
                this. size = size;
                this.color = color;
            }

            draw() {
                context.beginPath()
                context.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
                context.lineWidth = 2;
                // context.strokeStyle = 1;
                context.fillStyle = '#ffffff';
                context.fill();
                //context.stroke();
            }

            update() {
                if(this.y > canvas.height || this.y < 0) {
                    this.directionY = -this.directionY
                }
                if(this.x > canvas.width || this.x < 0) {
                    this.directionX = -this.directionX
                }

                let dx = this.x;
                let dy = this.y;
                let distance = Math.sqrt(dx*dx + dy*dy);
                if (distance < this.size) {
                    if(this.x && this.x < canvas.width - this.size * 10) {
                        this.x += 10
                    }
                    if(this.x && this.x > canvas.width - this.size * 10) {
                        this.x -= 10
                    }
                    if(this.y && this.y < canvas.width - this.size * 10) {
                        this.y += 10
                    }
                    if(this.y && this.y > canvas.width - this.size * 10) {
                        this.y -= 10
                    }
                }
                this.x += this.directionX;
                this.y += this.directionY;
                this.draw()
            }
        }

        function init() {
            particlesArray = []
            let numberOfParticles = (canvas.height * canvas.width) / 15000;
            for(let i = 0; i < numberOfParticles; i++) {
                let size = (Math.random() * 10) + 1;
                let x = (Math.random() * ((innerWidth - size * 2) - (size * 2)) + size * 2);
                let y = (Math.random() * ((innerHeight - size * 2) - (size * 2)) + size * 2);
                let directionX;
                let directionY;
                if(i % 2 === 0) {
                    directionX = (Math.random() * 1) - 0.5
                    directionY = (Math.random() * 1) - 0.5
                } else {
                    directionX = (Math.random() * 1) + 0.5
                    directionY = (Math.random() * 1) + 0.5

                }
                let color = '#ffffff';

                particlesArray.push(new Particle(x, y, directionX, directionY, size, color))
                
            }
        }

        
        function connect() {
            let opacity = 1

            for(let a = 0; a < particlesArray.length; a++) {
                for(let b = a; b < particlesArray.length; b++) {
                    let distance = (( particlesArray[a].x - particlesArray[b].x ) * ( particlesArray[a].x - particlesArray[b].x)) 
                    + (( particlesArray[a].y - particlesArray[b].y ) * ( particlesArray[a].y - particlesArray[b].y))
                    
                    if(distance < (canvas.width / 7) * (canvas.height / 7)) {
                        opacity = 1 - (distance/20000)
                        context.strokeStyle = `rgba(255,255,255, ${opacity})`;
                        context.lineWidth = 1;
                        context.beginPath();
                        context.moveTo(particlesArray[a].x, particlesArray[a].y)
                        context.lineTo(particlesArray[b].x, particlesArray[b].y)
                        context.stroke()
                        
                    }
                }
            }
        }
        
        function animate() {
            reqID = requestAnimationFrame(animate);
            context.clearRect(0,0, innerWidth, innerHeight)
            
            for (let i = 0; i < particlesArray.length; i++) {
                particlesArray[i].update()
            }
            connect()

            setTimeout(() => {
                console.log('Stopped')
                cancelAnimationFrame(reqID)
            }, delay)
        }

        window.addEventListener('resize', function() {
            canvas.width = innerWidth;
            canvas.height = innerHeight;
            canvas.radius = ((canvas.height / 80) * (canvas.height / 80));
            init()
        })

        // window.addEventListener('mouseout', function() {
        //     mouse.x = undefined;
        //     mouse.y = undefined
        // })

        init()
        animate()
    })
    
</script>

<style>
    #pattern-container {
        position: absolute;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        opacity: .45;
    }
    
</style>

<div id="pattern-container">
    <canvas bind:this={canvas}></canvas>
</div> -->


