<script>
  import { fade, fly } from 'svelte/transition'
  import { gsap } from 'gsap'
  import LoaderPattern from '../tools/LoaderPattern.svelte'
  import LoaderNetwork from '../tools/LoaderNetwork.svelte'
  import { onMount } from 'svelte';

  export let visible;
  let play = true;
  let delay = 4000;

  onMount(() => {
    // Simple GSAP for Symbols
    // gsap.to(".cls-1", { 
    //     "stroke-dashoffset": 0,
    //     duration: 6,
    //     ease: "power4.inOut"
    //   })
    gsap.to(".quote", {
      y: -30,
      duration: 5,
      opacity: 1
    })
  }) 
</script>

<style lang="scss">
  @import '../../styles/abstracts/mixins';
  @import '../../styles/abstracts/variables';

  #pre-loader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #000000;

  }
  #icon-container {
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    
    @include respond(phone) {
        flex-direction: column;
    }

    .icon {
      max-width: 100px;
      margin: 1em;

      @include respond(phone) {
        min-width: 200px;
        opacity: .25;
      }
    }
    .cls-1{
      fill: none;
      stroke: $white;
      stroke-width: .75px;
      stroke-miterlimit:10;
      stroke-dasharray: 600;
      stroke-dashoffset: 600;
      fill-opacity: 0;
    }
  }

  .quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    transform-origin: center;
    text-align: center;
    min-width: 80vw;
    opacity: 0;

    @include respond(phone) {
      top: 50%;
    }
    

    p {
      color: $white;
      font-size: $large-font-size;

      @include respond(phone) {
        font-size: $mid-font-size;
      }
    }
  }
</style>

<div 
  id="pre-loader"
  transition:fade="{{delay: delay}}"
  on:introstart="{() => visible = false}"
  on:outroend="{() => visible = true}"
>
  {#if play}
    <!-- <LoaderNetwork/>   -->
  {:else}
  {/if}
  <LoaderNetwork delay={delay + 600} />
  <!-- <LoaderPattern/> -->
  <!-- <div id="icon-container">
    <svg class="icon" id="action" viewBox="0 0 98.04 98.04">
      <path class="cls-1" d="M34.45,59.08,21.89,66.92a18.62,18.62,0,0,0,8.27,2l10.42-.53,2.29-1.43A20.39,20.39,0,0,1,34.45,59.08Z"/>
      <path class="cls-1" d="M42.87,30.36l-2.29-1.43L30.16,28.4a18.62,18.62,0,0,0-8.27,2l12.56,7.85A20.41,20.41,0,0,1,42.87,30.36Z"/>
      <path class="cls-1" d="M72.19,48.67l0,0,0,0L42.87,30.36a20.41,20.41,0,0,0-8.42,7.85L51.21,48.67,34.45,59.08a20.39,20.39,0,0,0,8.42,7.84Z"/>
      <path class="cls-1" d="M49.46,1.08A47.72,47.72,0,1,0,97.17,48.8,47.73,47.73,0,0,0,49.46,1.08Zm-36,58a20.33,20.33,0,0,1-2.93-10.33s0,0,0-.08v-.05c0-.09,0-.1,0-.09a20.3,20.3,0,0,1,2.93-10.32L30.14,48.62ZM93.13,48.64l0,0L61.56,68.35l-10.42.53a18.65,18.65,0,0,1-8.27-2l-2.29,1.43-10.42.53a18.62,18.62,0,0,1-8.27-2l12.56-7.84a20.53,20.53,0,0,1-2.93-10.33s0,0,0-.08v-.05c0-.09,0-.1,0-.09a20.49,20.49,0,0,1,2.93-10.32L21.89,30.36a18.62,18.62,0,0,1,8.27-2l10.42.53,2.29,1.43a18.65,18.65,0,0,1,8.27-2l10.42.53L93.09,48.62Z"/>
    </svg>
    <svg class="icon" id="welcoming" viewBox="0 0 98.04 98.04">
      <path class="cls-1" d="M49.21,83.82,69.13,51.9l.54-10.55a18.83,18.83,0,0,0-2-8.37L49.21,62.58l0,0,0,0L30.68,33a18.83,18.83,0,0,0-2,8.37l.54,10.55L49.16,83.82l0,0Z"/>
      <circle class="cls-1" cx="49.46" cy="48.8" r="47.71"/>
      <path class="cls-1" d="M53.86,23.54,49.22,31l-4.64-7.43a5.65,5.65,0,0,0-7.78-1.8h0A5.66,5.66,0,0,0,35,29.53L49.17,52.22,63.44,29.53a5.66,5.66,0,0,0-1.8-7.79h0A5.65,5.65,0,0,0,53.86,23.54Z"/>
      <polygon class="cls-1" points="45.72 17.31 49.17 22.84 52.65 17.31 45.72 17.31"/>
    </svg>
    <svg class="icon" id="prescient" viewBox="0 0 98.04 98.04">
      <path class="cls-1" d="M49.46,1.08A47.72,47.72,0,1,0,97.17,48.8,47.73,47.73,0,0,0,49.46,1.08Zm35.61,48.6C85,59.16,77.31,67.37,67.83,67.37l-9.16-.47-9-5.64-9,5.64-9.15.47c-9.49,0-17.18-8.21-17.24-17.69,0,0,0,0,0-.07v0c0-.08,0-.09,0-.08C14.26,40,22,31.8,31.44,31.8l9.15.47,9,5.64,9-5.64,9.16-.47C77.31,31.8,85,40,85.07,49.49v.19Z"/>
      <path class="cls-1" d="M68.3,49.57,49.63,37.91,30.9,49.57l0,0L49.63,61.26ZM49.43,55a5.69,5.69,0,1,1,5.69-5.69A5.68,5.68,0,0,1,49.43,55Z"/>
      <path class="cls-1" d="M49.46,1.08A47.72,47.72,0,1,0,97.17,48.8,47.73,47.73,0,0,0,49.46,1.08Zm35.61,48.6C85,59.16,77.31,67.37,67.83,67.37l-9.16-.47-9-5.64-9,5.64-9.15.47c-9.49,0-17.18-8.21-17.24-17.69,0,0,0,0,0-.07v0c0-.08,0-.09,0-.08C14.26,40,22,31.8,31.44,31.8l9.15.47,9,5.64,9-5.64,9.16-.47C77.31,31.8,85,40,85.07,49.49v.19Z"/>
    </svg>
    <svg class="icon" id="humble" viewBox="0 0 98.04 98.04">
      <path class="cls-1" d="M55.43,67.21,55,58.06l-5.64-9L55,40l.47-9.16A17,17,0,0,0,49,17.69a17,17,0,0,0-6.41,13.13L43.08,40l5.64,9-5.64,9-.47,9.15A17,17,0,0,0,49,80.35,17,17,0,0,0,55.43,67.21Z"/>
      <path class="cls-1" d="M49.11,38.77l0,0L37.52,20.2l-6.7-.34c-9.48,0-17.17,8.21-17.24,17.69v.19A18.08,18.08,0,0,0,17.69,49a17,17,0,0,1,13.13-6.41l9.16.47,9,5.64,9-5.64,9.15-.47A17,17,0,0,1,80.35,49a18.07,18.07,0,0,0,4.1-11.28s0,0,0-.07v-.05c0-.08,0-.08,0-.07-.06-9.48-7.75-17.69-17.24-17.69l-6.5.33Z"/>
      <path class="cls-1" d="M67.21,55.43,58.06,55l-9-5.64L40,55l-9.16.47A17,17,0,0,1,17.69,49,18,18,0,0,0,13.58,60.3v.19c.07,9.47,7.76,17.69,17.24,17.69l6.71-.34L49.09,59.31,60.7,77.85l6.51.33c9.49,0,17.18-8.22,17.24-17.69,0,0,0,0,0-.07v-.05c0-.08,0-.08,0-.07A18,18,0,0,0,80.35,49,17,17,0,0,1,67.21,55.43Z"/>
      <path class="cls-1" d="M67.21,42.61l-9.15.47-9,5.64-9-5.64-9.16-.47A17,17,0,0,0,17.69,49a17,17,0,0,0,13.13,6.41L40,55l9-5.64,9,5.64,9.15.47A17,17,0,0,0,80.35,49,17,17,0,0,0,67.21,42.61Z"/>
      <circle class="cls-1" cx="49.46" cy="48.8" r="47.71"/>
    </svg>
    <svg class="icon" id="bright" viewBox="0 0 98.04 98.04">
      <path class="cls-1" d="M49.46,1.08A47.72,47.72,0,1,0,97.17,48.8,47.73,47.73,0,0,0,49.46,1.08Zm34,47.45,0,0-9.93,6.19,5.69,10.63h0v0l-11.73.39-.41,12v0h0L56.7,72.3,50.34,82.54l0,0v0l-6.19-10L33.51,78.22h0v0l-.4-11.69-12-.4,0,0,5.5-10.35L16.32,49.36l10-6.19L20.64,32.56l11.7-.43.39-12h0v0L43.1,25.61l6.38-10.22v0l6.2,10,10.63-5.69,0,0,.38,11.72,12,.42h0v0l-5.5,10.33,10.2,6.36Z"/>
      <path class="cls-1" d="M58.89,43.31A10.07,10.07,0,0,0,60.4,38l0-.39h0a10.25,10.25,0,0,0-5.52,1.94,10.09,10.09,0,0,0-1.33-5.33L53.38,34l-.15,0a10.25,10.25,0,0,0-3.72,4.37,10,10,0,0,0-3.82-3.94l-.23-.12-.16,0a10.16,10.16,0,0,0-1,5.64A10.12,10.12,0,0,0,39,38.46l-.34,0,0,.05A10.2,10.2,0,0,0,40.52,44a10,10,0,0,0-5.32,1.33l-.33.21a10.19,10.19,0,0,0,4.43,3.81,10.12,10.12,0,0,0-3.95,3.82l-.17.34a10.24,10.24,0,0,0,5.74,1.09,10.07,10.07,0,0,0-1.51,5.28l0,.39a10.24,10.24,0,0,0,5.51-1.94,10.07,10.07,0,0,0,1.34,5.33l.21.33a10.24,10.24,0,0,0,3.81-4.43,10,10,0,0,0,3.82,3.94l.34.18a10.24,10.24,0,0,0,1.09-5.74,10.07,10.07,0,0,0,5.28,1.51l.38,0a10.21,10.21,0,0,0-1.93-5.52,10.09,10.09,0,0,0,5.33-1.33l.33-.21a10.27,10.27,0,0,0-4.44-3.81,10.06,10.06,0,0,0,4-3.82l.18-.34A10.26,10.26,0,0,0,58.89,43.31Z"/>
    </svg>
  </div> -->
  <div class="quote">
    <p>How do we build an innovation ecosystem that works for everyone?</p>
  </div>
</div>